import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import noop from 'lodash-es/noop';
import { ME_Fit } from 'me-fit-typings';
import { Feedback } from 'src/app/decorators/feedback-decorator';
import { CustomersStateService } from 'src/app/state/customers.state.service';
import { InlineSearchFunction, InlineSearchItem } from '../../components/inline-search/inline-search.component';
import { SearchService } from 'src/app/services/search.service';

interface CreateFormValue {
  firstName: string;
  lastName: string;
  organizationGroups: ME_Fit.OrganizationGroup[];
}


@Component({
  selector: 'app-customer-create-modal',
  templateUrl: './customer-create-modal.component.html',
  styleUrls: ['./customer-create-modal.component.css']
})
export class CustomerCreateModalComponent implements OnInit {

  customerCreateForm = this.fb.group({
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    organizationGroups: [[]],
  });

  groupSearchFn: InlineSearchFunction = this.$search.searchGroups;

  public get selectedGroupsForCreateCustomer() {
    return this.customerCreateForm.get('organizationGroups')!.value ?? [];
  }

  constructor(
    private modalCtrl: ModalController,
    private fb: UntypedFormBuilder,
    private $customerState: CustomersStateService,
    private $search: SearchService
  ) { }

  ngOnInit() {
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  onAddCustomerFormSubmit() {
    if (this.customerCreateForm.invalid) {
      return;
    }

    this.addCustomer(this.customerCreateForm.value);
  }

  @Feedback({
    i18nFailKey: 'customer.delete.fail',
    i18nSuccessKey: 'customer.add.success',
    i18nLoadingMessage: 'customer.add.loading'
  })
  async addCustomer(customerCreateFormValue: CreateFormValue) {
    let promise: Promise<ME_Fit.OrganizationCustomer>;

    try {
      const { firstName, lastName, organizationGroups } = customerCreateFormValue;

      promise = this.$customerState.create.call({
        request: {
          firstName,
          lastName,
          organizationGroupIds: organizationGroups.map(group => group.id),
          notes: []
        }
      });

      const customer = await promise;


      this.modalCtrl.dismiss(customer);
    } catch (error) {
      console.error('[CustomerCreateModalComponent:addCustomer] error', error);
    }

    return promise!;
  }

  createCustomerGroupSelected(event: InlineSearchItem<ME_Fit.OrganizationGroup>) {
    console.log('createCustomerGroupSelected', event);

    const groupsCtrl = this.customerCreateForm.get('organizationGroups')!;
    groupsCtrl.setValue(
      [...groupsCtrl.value, event.rawObject]
    );
  }

  createCustomerGroupRemoved(groupId: string) {
    const groupsCtrl = this.customerCreateForm.get('organizationGroups')!;
    groupsCtrl.setValue(
      groupsCtrl.value.filter(group => group.id !== groupId)
    );
  }


}
